<template>
    <v-overlay :value="deleteOverlay" opacity="0.85">
        <v-col cols="12" class="d-flex flex-column align-center">
            <v-card class="pa-6">
                <div class="text-h4 error--text font-weight-thin pb-4">
                    Warning!
                </div>
                <div v-if="deleteItem.collectionName === 'trains'" class="body-1 pb-8">
                    Are you sure you want to delete this train?
                </div>
                <div v-if="deleteItem.collectionName === 'messages'" class="body-1 pb-8">
                    Are you sure you want to delete this message?
                </div>
                <div v-if="deleteItem.collectionName === 'adverts'" class="body-1 pb-8">
                    Are you sure you want to delete this advert?
                </div>
                <div v-if="deleteItem.collectionName === 'destinations'" class="body-1 pb-8">
                    Are you sure you want to delete this destination?
                </div>
                <div v-if="deleteItem.collectionName === 'patterns'" class="body-1 pb-8">
                    Are you sure you want to delete this calling pattern?
                </div>
                <v-row>
                    <v-col cols="6">
                        <v-btn block class="darkgrey" @click="deleteOverlay = false && changeDeleteOverlay"
                            >Cancel</v-btn
                        >
                    </v-col>
                    <v-col cols="6">
                        <v-btn
                            block
                            class="primary"
                            @click="deleteDocument(deleteItem.collectionName, deleteItem.id)"
                            >Delete</v-btn
                        >
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-overlay>
</template>

<script>
export default {
    data: () => ({
        result: {}
    }),
    props: {
        deleteOverlay: {
            type: Boolean,
            required: false,
            default: function() {
                return false
            },
        },
        deleteItem: {
            type: Object,
            required: false
        }
    },
    methods: {
        deleteDocument: async function(collectionName, id) {
            let t = this;
            var documentResult = await t.FB_deleteDocument(collectionName, id);
            t.result = documentResult;
            this.deleteOverlay = false
            this.changeDeleteOverlay()
            this.documents = await this.getDocuments(collectionName);
        },
        changeDeleteOverlay() {
            this.$emit('changeDeleteOverlay')
        },
        getDocuments: async function (collectionName) {
            let t = this;
            var collection = t.$firebase.db.collection(collectionName)
            collection.onSnapshot(function (querySnapshot) {
                t.documents = [];
                querySnapshot.forEach(function (doc) {
                    var document = doc.data()
                    document.id = doc.id;
                    /*eslint-disable */
                    if (!document.hasOwnProperty('delete')) {
                        t.documents.push(document);
                    }
                    /*eslint-enables */
                });
            });
        },  
    }
}
</script>