<template>
    <section>
        <v-app-bar
            app
            v-if="$route.path.includes('admin')"
            class="black"
            absolute
        >
            <img src="@/assets/tfwlogo.png" height="40" @click="MIX_go('/welcome')" style="cursor: pointer !important;"/>
            <v-spacer />
            <v-app-bar-nav-icon
                v-if="$vuetify.breakpoint.smAndDown"
                dark
                @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <div v-if="$vuetify.breakpoint.mdAndUp">
                <v-btn
                    large
                    depressed
                    dark
                    class="ml-1"
                    v-for="(item, index) in items"
                    :to="item.path"
                    :key="index"
                    ><v-icon class="mr-3">{{ item.icon }}</v-icon>
                    {{ item.title }}</v-btn
                >
                <v-btn
                    large
                    depressed
                    dark
                    class="ml-1"
                    @click="logout()"
                    ><v-icon class="mr-3">mdi-account</v-icon>
                    Log Out</v-btn
                >
            </div>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" absolute temporary>
            <v-list dense>
                <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    link
                    :to="item.path"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-left">{{
                            item.title
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="logout()" >
                    <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-left">Log Out</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
        </v-navigation-drawer>
    </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: "NavigationComponent",
    data: () => ({
        drawer: null,
        items: [
            { title: "Zones", icon: "mdi-television", path: "/admin/zones" },
            { title: "Trains", icon: "mdi-train", path: "/admin/trains" },
            { title: "Messages", icon: "mdi-forum", path: "/admin/messages" },
            { title: "Adverts", icon: "mdi-image", path: "/admin/adverts" },
            { title: "Destinations", icon: "mdi-map-marker", path: "/admin/destinations", },
            { title: "Calling Patterns", icon: "mdi-keyboard-tab", path: "/admin/callingpatterns", },
            { title: "Users", icon: "mdi-account", path: "/admin/users", },
            { title: "Preview", icon: "mdi-television-guide", path: "/admin/preview", },
            // { title: "Log Out", icon: "mdi-account", event: this.logout() },
        ],
    }),
    methods: {
        ...mapActions({
            ACT_currentUser: "ACT_currentUser",
        }),
		logout() {
            this.ACT_currentUser(null);
			this.$firebase.auth.signOut().then(() => {
				this.showPageElements = false;
				this.$router.push("/").catch((err) => {
					console.log("Route error: " + err);
				});
			});
		},
    }
};
</script>

<style lang="scss">
tbody {
    tr:hover {
        background-color: transparent !important;
    }
}
</style>