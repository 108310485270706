import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import alertbox_store from '@/modules/alertbox/alertbox_store.js'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    modules: {
        alertbox_store: alertbox_store,
    },
    state: {
        currentUser: null,
    },
    mutations: {
        MUT_currentUser(state, payload) { state.currentUser = payload },
    },
    actions: {
        ACT_currentUser({ commit }, payload) { commit('MUT_currentUser', payload) },
    },
    getters: {
        GET_currentUser(state) { return state.currentUser },
    }
})
