import { mapGetters } from 'vuex'

const mixins = {
    data: () => ({
        leftColumn: 12,
        rightColumn: 0,
        MIX_formMode: 'Add'
    }),
    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
        }),
    },
    methods: {
        MIX_go(path) {
            this.$router.push(path).catch(err => {
                console.log('Route error: ' + err)
            })
            this.open = false;
        },
        MIX_back() {
            this.$router.go(-1)
        },
        MIX_panel(action) {
            console.log('this.$vuetify.breakpoint.smAndDown = ' + this.$vuetify.breakpoint.smAndDown)
            switch (true) {
                case ((action === 'show') && (this.$vuetify.breakpoint.smAndDown)):
                    console.log('1')
                    this.leftColumn = 0;
                    this.rightColumn = 12;
                    break;
                case ((action === 'show') && (this.$vuetify.breakpoint.mdAndUp)):
                    console.log('2')
                    this.leftColumn = 8;
                    this.rightColumn = 4;
                    break;
                case (action === 'hide'):
                    this.leftColumn = 12;
                    this.rightColumn = 0;
                    break;
            }
        },
        MIX_createDocument: async function (collectionName, form) {
            var t = this;
            if (form.zoneName !== undefined) {
                form.id = form.zoneName.replace(/\s+/g, '').toUpperCase();
            }
            if (form.destinationName !== undefined) {
                form.id = form.destinationName.replace(/\s+/g, '').toUpperCase();
            }
            if (form.patternName !== undefined) {
                form.id = form.patternName.replace(/\s+/g, '').toUpperCase();
            }
            var documentResult = await this.FB_createDocument(collectionName, form);
            if (documentResult.code === 1) {
                this.MIX_alertBox({ 'show': true, 'text': 'Saved', 'backgroundColor': 'success', 'color': 'white', 'timeout': 5000 })
                t.MIX_panel('hide')
            } else {
                this.MIX_alertBox({ 'show': true, 'text': 'Error Saving', 'backgroundColor': 'error', 'color': 'white', 'timeout': 5000 })
            }
        },
        MIX_deleteDocument: async function (id) {
            var t = this;
            var documentResult = await t.FB_deleteDocument(t.collectionName, id);
            if (documentResult.code === 1) {
                this.MIX_alertBox({ 'show': true, 'text': 'Deleted', 'backgroundColor': 'success', 'color': 'white', 'timeout': 5000 })
                t.MIX_panel('hide')
            } else {
                this.MIX_alertBox({ 'show': true, 'text': 'Error Deleting', 'backgroundColor': 'error', 'color': 'white', 'timeout': 5000 })
            }
        },
        MIX_cancelDocument() {
            this.MIX_panel('hide');
        },
        MIX_updateDocument: async function (collectionName, form) {
            var t = this;
            var documentResult = await this.FB_updateDocument(collectionName, form);
            this.result = documentResult;
            if (documentResult.code === 1) {
                t.MIX_panel('hide')
                this.MIX_alertBox({ 'show': true, 'text': 'Updated', 'backgroundColor': 'success', 'color': 'white', 'timeout': 5000 })
            } else {
                this.MIX_alertBox({ 'show': true, 'text': 'Error Updating', 'backgroundColor': 'error', 'color': 'white', 'timeout': 5000 })
            }
        },
        MIX_generateId() {
            return "xxxxyxxxyxxxxxxxxx".replace(/[xy]/g, function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
    },
}

export default {
    install(Vue) {
        Vue.mixin(mixins)
    },
}
