<template>
    <div class="grey darken-4" style="height: 100vh !important">
        <div
            v-if="zone.screen === 'TRAINS'"
            class="grey darken-4"
            style="width: 100vw !important"
        >
            <v-row
                class="display-3 font-weight-black white--text black pa-5"
                no-gutters
            >
                <v-col class="text-left d-flex align-center justify-start pl-8" cols="4"
                    >EXPECTED</v-col
                >
                <v-col cols="4">
                    <v-img contain height="150" src="@/assets/tfwlogo.png" />
                </v-col>
                <v-col class="d-flex align-center justify-end pr-8" cols="4"
                    >PLATFORM</v-col
                >
            </v-row>
        </div>
        <div
            v-if="zone.screen === 'TRAINS'"
            class="grey darken-4"
            style="
                width: 100vw !important;
                height: calc(100vh - 280px) !important;
            "
        >
            <v-row
                v-for="train in computedTrains"
                :key="train.id"
                class="train font-weight-black white--text grey darken-4 pa-5"
                no-gutters
                style="
                    border-top: 5px solid red !important;
                    height: 270px !important;
                "
            >
                <div v-if="train.loading" fill-height style="position: absolute !important; left: -1px !important; top: 0px !important; z-index: 9999999999 !important; height: 100vh !important; background-color: #000000 !important; width: 100vw !important;" class="d-flex align-center flex-column justify-center black">
                    <div style="font-size: 130px !important;"><span class="grey--text text--lighten-1">{{ $moment(train.date, "X").format("HH:mm") }}</span> <span>{{ train.destination }}</span></div>
                    <div class="font-weight-thin animate__animated animate__flash animate__infinite animate__slower">LOADING SHORTLY</div>
                    <div><span class="grey--text text--lighten-1">PLATFORM {{ train.platform }}</span></div>
                    <!-- <div class="mt-10"><v-img contain height="50" src="@/assets/tfwlogo.png" /></div> -->
                </div>
                <v-col v-if="!train.loading" class="text-left grey--text text--lighten-1 pl-8" cols="3">
                    <span v-if="!train.delayed">{{ $moment(train.date, "X").format("HH:mm") }}</span>
                    <span v-if="train.delayed" class="font-weight-bold error--text delayed">{{ delayText }}</span>
                </v-col>
                <v-col v-if="!train.loading" class="text-left text-truncate" style="max-width: 100%" cols="8">{{
                    getTrainDestination(train.destination)
                }}</v-col>
                <v-col
                    v-if="!train.loading"
                    class="text-right pr-8 grey--text text--lighten-1"
                    cols="1"
                    >{{ train.platform }}</v-col
                >
                <v-col v-if="!train.loading" class="display-2 px-10" cols="12">
                    <VTextMarquee>
                        {{ getPatternStations(train.pattern) }}</VTextMarquee
                    >
                </v-col>
            </v-row>
        </div>
        <div
            v-if="zone.screen === 'MESSAGES'"
            class="black d-flex align-center justify-center flex-column"
            style="
                width: 100vw !important;
                height: calc(100vh - 80px) !important;
            "
        >
            <!-- Where the messages are shown from -->
            <div
                style="
                    position: fixed;
                    left: 0px !important;
                    top: 0px !important;
                "
                class="mt-10 ml-10"
            >
                <img src="@/assets/tfwlogo.png" width="600" />
            </div>
            <messages-screen :key="messageKey" />
        </div>
        <div
            v-if="zone.screen === 'ADVERTS'"
            class="black d-flex align-center justify-center flex-column"
            style="width: 100vw !important; height: 100vh !important"
        >
            <!-- Where the messages are shown from -->
            <v-carousel
                hide-delimiters
                continuous
                cycle
                :interval="10000"
                :show-arrows="false"
                height="100%"
                v-if="advert.advertType === 'Images'"
            >
                <v-carousel-item
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                    v-for="(item, i) in files"
                    :key="i"
                    :src="item.fileURL"
                ></v-carousel-item>
            </v-carousel>
            <vue-core-video-player
                :src="files[0].fileURL"
                loop
                :controls="false"
                v-if="advert.advertType === 'Video'"
            ></vue-core-video-player>
        </div>
        <v-footer
            v-if="zone.screen !== 'ADVERTS'"
            class="
                d-flex
                black
                white--text
                text-h5
                font-weight-normal
            "
            style="
                border-top: 5px solid red !important;
                height: 90px !important;
            "
        >   
            <v-col cols="4">
                <v-img contain height="30" src="@/assets/vindicologo.png"/>
            </v-col>
            <v-col cols="4">
                {{ $route.params.id.toUpperCase() }}
            </v-col>
            <v-col cols="4">
                <span
                    class="
                        animate__animated
                        animate__flash
                        animate__slower
                        animate__infinite
                        infinite
                        text-right
                    "
                    >{{ update }}</span
                >
            </v-col>
        </v-footer>
    </div>
</template>


<script>
export default {
    name: 'Zone',
    data: () => ({
        collectionName: "trains",
        delayText: "",
        trains: [],
        files: [],
        speed: 100,
        scrollAnimation: true,
        patterns: [],
        messages: [],
        messageKey: 0,
        zone: {},
        showMessages: false,
        messageTime: 10,
        trainTime: 10,
        enableMessages: true,
        advert: {
            id: "",
            advertName: "",
            advertType: "",
        },
        update: "",
        destinations: []
    }),
    computed: {
        computedTrains() {
            var trains = this.$_.sortBy(this.trains, "date");
            trains = this.$_.filter(trains, (e) => {
                return e.enabled === true;
            });
            return this.$_.take(trains, 3);
        },
    },
    watch: {
        zone: {
            handler: async function () {
                var t = this;
                t.showUpdate();
                // t.showDelay();
                if (t.zone.screen === "ADVERTS") {
                    t.files = await t.getFiles(t.zone.advertId);
                    var advertResult = await t.FB_readDocumentById(
                        "adverts",
                        t.zone.advertId
                    );
                    t.advert = advertResult.data;
                }
                if (t.zone.screen === "MESSAGES") {
                    this.messages = await this.getDocuments("messages");
                    t.messageKey++;
                }
                if (t.zone.screen === "TRAINS") {
                    this.patterns = await this.getDocuments("patterns");
                    this.getTrains();
                }
            },
            deep: true,
        },
    },
    methods: {
        showUpdate: function () {
            var t = this;
            t.update = "UPDATING";
            setTimeout(() => {
                t.update = "";
            }, 5000);
        },
        showDelay: function () {
            var t = this;
            t.delayText = "DELAY";
            setTimeout(() => {
                t.computedTrains.forEach(train => {
                    t.delayText = t.$moment(train.delayedTime, "X").format("HH:mm");
                })
            }, 4000);
        },
        getPatternStations(id) {
            let t = this;
            var patternStations = t.$_.find(t.patterns, function (e) {
                return e.patternName === id;
            });
            return patternStations.patternStations;
        },
        getTrainDestination(id) {
            var destinations = this.destinations;

            var destinationHasId = []
            destinationHasId = destinations.filter(function(item) {
                return item.destinationName === id
            })
            
            if (destinationHasId.length > 0) {
                return id
            } else {
                var destination = destinations.filter(function(item) {
                    return item.id === id
                })
                console.log("destination = " + JSON.stringify(destination[0].destinationName, null, 2))
                return destination[0].destinationName
            }
        },
        getMessage(id) {
            let t = this;
            var message = t.$_.find(t.messages, function (e) {
                return e.id === id;
            });
            return message;
        },
        getTrains: function () {
            let t = this;
            var collection = t.$firebase.db.collection("trains");
            collection.onSnapshot(function (querySnapshot) {
                t.trains = [];
                querySnapshot.forEach(function (doc) {
                    var document = doc.data();
                    document.id = doc.id;
                    /*eslint-disable */
                    if (
                        !document.hasOwnProperty("delete") &&
                        document.zone === t.$route.params.id.toUpperCase()
                    ) {
                        t.trains.push(document);
                    }
                    /*eslint-enables */
                });
            });
        },
        getFiles: function (advertId) {
            let t = this;
            console.log("advertId = " + advertId);
            t.$firebase.db
                .collection("files")
                .where("docLink.documentId", "==", advertId)
                .where("docLink.type", "==", "advert")
                .onSnapshot(function (querySnapshot) {
                    console.log("here");
                    t.files = [];
                    querySnapshot.forEach(function (doc) {
                        console.log("doc.id = " + doc.id);
                        var document = doc.data();
                        document.id = doc.id;
                        /*eslint-disable */
                        if (!document.hasOwnProperty("delete")) {
                            t.files.push(document);
                            console.log(
                                "document = " +
                                    JSON.stringify(document, null, 2)
                            );
                        }
                        /*eslint-enables */
                    });
                });
        },
        getDocuments: function (collectionName) {
            return new Promise((resolve) => {
                let t = this;
                var collection = t.$firebase.db.collection(collectionName);
                collection.onSnapshot(function (querySnapshot) {
                    var documents = [];
                    querySnapshot.forEach(function (doc) {
                        var document = doc.data();
                        document.id = doc.id;
                        /*eslint-disable */
                        if (!document.hasOwnProperty("delete")) {
                            documents.push(document);
                        }
                        /*eslint-enables */
                    });
                    resolve(documents);
                });
            });
        },
        getZone() {
            let t = this;
            t.$firebase.db
                .collection("zones")
                .doc(t.$route.params.id.toUpperCase())
                .onSnapshot((doc) => {
                    t.zone = doc.data();
                });
        },
        getDocumentsOther: function(collectionName) {
            return new Promise((resolve) => {
                let t = this;
                var collection = t.$firebase.db.collection(collectionName)
                collection.onSnapshot(function(querySnapshot) {
                    var documents = [];
                    querySnapshot.forEach(function(doc) {
                        var document = doc.data()
                        document.id = doc.id;
                        /*eslint-disable */
                        if (!document.hasOwnProperty('delete')) {
                            documents.push (document);
                        }
                        /*eslint-enables */
                    });
                    resolve (documents)
                })
            })
        },
    },
    async created() {
        let t = this;
        await t.getZone();
        t.showDelay();
        setInterval(() => {
            t.showDelay()
        }, 8000);
    },
    async mounted() {
        var t = this;
        this.destinations = await this.getDocumentsOther('destinations');
        setInterval(async () => {
            await t.getZone();
        }, 60000);
    }
};
</script>

<style>
.train {
    font-size: 120px !important;
}

.delayed {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>