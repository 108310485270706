<template>
    <div fill-height class="d-flex align-stretch flex-grow-1 headermargin">
        <DeleteOverlay :deleteOverlay="deleteOverlay" :deleteItem="deleteItem" @changeDeleteOverlay="deleteOverlay = false"/>
        <v-row no-gutters fill-height class="pink">
            <v-col :cols="leftColumn" v-if="leftColumn > 0" class="grey lighten-2 text-left" fill-height>
                <div class="d-flex justify-space-between grey darken-2 pa-5 align-center">
                    <div><h1 class="white--text">Zones</h1></div>
                    <v-spacer />
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="mr-4"><v-text-field dense solo clearable background-color="white" v-model="search"  label="Search" hide-details="auto"></v-text-field></div>
                    <div><v-btn large class="primary white--text ml-0" depressed @click="MIX_formMode = 'Add', form = defaultForm; MIX_panel('show');">Add <span v-if="$vuetify.breakpoint.mdAndUp">Zones</span></v-btn></div>
                </div>
                <div v-if="$vuetify.breakpoint.smAndDown" class="grey darken-2 pb-4 px-5"><v-text-field dense solo clearable background-color="white" v-model="search"  label="Search" hide-details="auto"></v-text-field></div>
                <v-data-table :search="search" :headers="headers" :items="computedDocuments" :items-per-page="10" class="elevation-1" >
                    <template v-slot:[`item.message`]="{ item }">
                        <span v-if="item.screen === 'MESSAGES'">{{ item.message }}</span>
                    </template>
                    <template v-slot:[`item.advert`]="{ item }">
                        <span v-if="item.screen === 'ADVERTS'">{{ item.advert }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn class="darken-3 ml-0" depressed @click="MIX_formMode='Edit'; editDocument(item.id)"><v-icon class="black--text">mdi-pencil</v-icon></v-btn>
                        <v-btn class="darken-3 ml-1" depressed @click="deleteOverlay = true, deleteItem.id = item.id, deleteItem.collectionName = collectionName"><v-icon class="black--text">mdi-delete</v-icon></v-btn>
                    </template>
                </v-data-table>
            </v-col>

            <v-col :cols="rightColumn" v-if="rightColumn > 0" class="grey lighten-3">
                <div class="d-flex align-center justify-space-between grey darken-3 pa-5">
                    <div><h1 class="white--text">{{ MIX_formMode }} Zone</h1></div>
                    <div><v-btn large class="grey darken-3 white--text ml-0" depressed @click="MIX_cancelDocument()"><v-icon>mdi-close</v-icon></v-btn></div>
                </div>
                <v-row class="pa-5">
                    <v-col cols="12">
                        <v-text-field background-color="white" v-model="form.zoneName" filled label="Zone Name" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-left">
                        <v-select background-color="white" v-model="form.screen" :items="screens" filled label="Output" hide-details="auto" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
                        <div class="mt-2 pa-1" v-if="form.screen === 'TRAINS'">You have selected to output TRAIN TIMES on Zone {{ form.zoneName }}, please use the [TRAINS] menu to configure the Train Times.</div>
                        <div class="mt-2 pa-1" v-if="form.screen === 'MESSAGES'">You have selected to output MESSAGES on Zone {{ form.zoneName }}, please select a message to output on this Zone.  If you want to create a new custom message then use the [MESSAGES] menu.</div>
                    </v-col>
                    <v-col cols="12" v-if="form.screen === 'MESSAGES'">
                        <v-select background-color="white" v-model="form.messageId" :items="messageDocuments" item-text="messageName" item-value="id" filled label="Message" hide-details="auto" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
                    </v-col>
                    <v-col cols="12" v-if="form.screen === 'ADVERTS'">
                        <v-select background-color="white" v-model="form.advertId" :items="advertDocuments" item-text="advertName" item-value="id" filled label="Advert" hide-details="auto" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
                    </v-col>
                    <v-col cols="6" class="text-left">
                        <v-btn large class="grey lighten-1 white--text ml-0" depressed @click="MIX_cancelDocument()">Cancel</v-btn>
                    </v-col>
                    <v-col cols="6" class="text-right">
                        <v-btn v-if="MIX_formMode === 'Add'" large class="primary white--text ml-0" depressed @click="saveZone()">Save</v-btn>
                        <v-btn v-if="MIX_formMode === 'Edit'" large class="primary white--text ml-0" depressed @click="updateZone()">Update</v-btn>                        
                        <!-- <v-btn v-if="MIX_formMode === 'Add'" large class="primary white--text ml-0" depressed @click="FB_createDocument(collectionName,form)">Save</v-btn>
                        <v-btn v-if="MIX_formMode === 'Edit'" large class="primary white--text ml-0" depressed @click="FB_updateDocument(collectionName,form)">Update</v-btn> -->
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "Zones",
        data: () => ({
            deleteOverlay: false,
            deleteItem: {
                id: '',
                collectionName: '',
            },
            defaultForm: {
                zoneName: '',
                screen: '',
                messageId: '',
            },
            form: {
                zoneName: '',
                screen: '',
                messageId: '',
            },
            collectionName: 'zones',
            headers: [
                {text: 'ID', value: 'id'},
                {text: 'Zone Name', value: 'zoneName'},
                {text: 'Output', value: 'screen'},
                {text: 'Message', value: 'message' },
                {text: 'Advert', value: 'advert' },
                {text: '', value: 'actions', align: 'end'}
            ],
            screens: ['TRAINS', 'MESSAGES', 'ADVERTS'],
            documents: [],
            messageDocuments: [],
            advertDocuments: [],
            messageNames: [],
            result: {},
            pageStatus: 'loading',
            search: ''
        }),
        computed: {
            computedDocuments() {
                var documentList = this.documents.map(e => {
                    return {
                        id: e.id,
                        zoneName: e.zoneName,
                        screen: e.screen,
                        message: this.lookupMessage(e.messageId),
                        advert: this.lookupAdvert(e.advertId)
                    }
                });
                return documentList;
            }
        },
        methods: {
            lookupMessage(messageId) {
                var message = this.messageDocuments.find(e => e.id == messageId);
                if (message !== undefined) {
                    return message.messageName;
                } else {
                    return '';
                }
            },
            lookupAdvert(advertId) {
                var adverts = this.advertDocuments.find(e => e.id == advertId);
                if (adverts !== undefined) {
                    return adverts.advertName;
                } else {
                    return '';
                }
            },            
            editDocument: async function (id) {
                this.MIX_panel('show');
                var document = this.documents.find(e => e.id === id);
                if (document !== undefined) {
                    this.form = JSON.parse(JSON.stringify(document));
                }
            },
            getDocumentsOther: async function (collectionName, documentArray) {
                return new Promise((resolve) => {
                    let t = this;
                    var collection = t.$firebase.db.collection(collectionName)
                    collection.onSnapshot(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            var document = doc.data()
                            document.id = doc.id;
                            /*eslint-disable */
                            if (!document.hasOwnProperty('delete')) {
                              documentArray.push(document);
                            }
                            /*eslint-enables */
                        });
                        resolve();
                    });
              });
            },
            getDocuments: async function () {
                    let t = this;
                    var collection = t.$firebase.db.collection('zones')
                    collection.onSnapshot(function (querySnapshot) {
                        t.documents = [];
                        querySnapshot.forEach(function (doc) {
                            var document = doc.data()
                            document.id = doc.id;
                            /*eslint-disable */
                            if (!document.hasOwnProperty('delete')) {
                              t.documents.push(document);
                            }
                            /*eslint-enables */
                        });
                    });
            },            
            //Updates toggle for showing messages or not.
            updateToggle: async function (document) {
                let t = this;
                if (document.screen === "TRAINS"){
                  document.screen = "MESSAGES";
                }
                else if (document.screen === "MESSAGES"){
                  document.screen = "TRAINS";
                }
                await t.FB_updateDocument(t.collectionName, document);
            },
            //Sends updated message to firebase
            updateMessage: async function (document, messageName) {
                let t = this;
                const index = t.messageNames.indexOf(messageName);
                const message = t.messageDocuments[index];
                document.messageId = message.id;
                await t.FB_updateDocument(t.collectionName, document)
            },
            //Fills list from message ids
            getMessageFromID: function (messageID) {
                for (const message of this.messageDocuments) {
                    if (message.id === messageID) {
                        console.log(message.messageName)
                        return message.messageName;
                    }
                }
            },
            //Toggles v-switch
            checkMessagesActive: function (item) {
                return item.screen === 'MESSAGES';
            },
            updateZone() {
                var t = this;
                t.form.zoneId = t.form.zoneName;
                this.FB_updateDocument(t.collectionName,t.form)
            },
            saveZone() {
                var t = this;
                var zoneId = t.form.zoneName;
                zoneId = zoneId.trim(); // remove outerspaces
                zoneId = zoneId.split(' ').join(''); // remove inner spaces
                zoneId = zoneId.toUpperCase(); // uppercase
                t.form.id = zoneId;
                t.form.zoneId = zoneId;
                this.FB_createDocument(t.collectionName,t.form)
            },
        },
        async created() {
            const t = this;
            await this.getDocuments();
            await this.getDocumentsOther("messages", t.messageDocuments)
            await this.getDocumentsOther("adverts", t.advertDocuments)
            for (const document of t.messageDocuments) {
                t.messageNames.push(document.messageName);
            }
        }
    }
</script>