<template>
    <v-container class="d-flex flex-column align-center">
        <v-col cols="12" class="flex-shrink-1">
            <img alt="TFW Logo" src="../assets/logo.png">
        </v-col>
        <v-col cols="12" lg="6" class="flex-grow-1">
            <FirebaseLogin />
        </v-col>
        <v-col cols="12" lg="6">
            <div class="grey--text justify-end">Version {{ LATEST_VERSION}}</div>
        </v-col>
    </v-container>
</template>

<script>
import { version } from '../../package.json'
export default {
    name: "Home",
    data() {
        return {
            LATEST_VERSION: version,
        }
    }
};
</script>
