<template>
    <div fill-height class="d-flex align-stretch flex-grow-1 headermargin">
        <DeleteOverlay :deleteOverlay="deleteOverlay" :deleteItem="deleteItem" @changeDeleteOverlay="deleteOverlay = false"/>
        <v-row no-gutters fill-height class="pink">
            <v-col :cols="leftColumn"  v-if="leftColumn > 0" class="grey lighten-2 text-left" fill-height>
                <div class="d-flex justify-space-between grey darken-2 pa-5 align-center">
                    <div><h1 class="white--text">Adverts</h1></div>
                    <v-spacer />
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="mr-4"><v-text-field dense solo clearable background-color="white" v-model="search"  label="Search" hide-details="auto"></v-text-field></div>
                    <div><v-btn large class="primary white--text ml-0" depressed @click="MIX_formMode = 'Add', form = defaultForm; form.id = MIX_generateId(); MIX_panel('show');">Add <span v-if="$vuetify.breakpoint.mdAndUp">Advert</span></v-btn></div>
                </div>
                <div v-if="$vuetify.breakpoint.smAndDown" class="grey darken-2 pb-4 px-5"><v-text-field dense solo clearable background-color="white" v-model="search"  label="Search" hide-details="auto"></v-text-field></div>                
                <v-data-table :search="search" :headers="headers" :items="documents" :items-per-page="20" class="elevation-1" >
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn class="darken-3 ml-0" depressed @click="MIX_formMode='Edit'; editDocument(item.id)"><v-icon class="black--text">mdi-pencil</v-icon></v-btn>
                        <v-btn class="darken-3 ml-1" depressed @click="deleteOverlay = true, deleteItem.id = item.id, deleteItem.collectionName = collectionName"><v-icon class="black--text">mdi-delete</v-icon></v-btn>
                    </template>                
                </v-data-table>
            </v-col>
            <v-col :cols="rightColumn" v-if="rightColumn > 0" class="grey lighten-3">
                <div class="d-flex align-center justify-space-between grey darken-3 pa-5">
                    <div><h1 class="white--text">{{ MIX_formMode }} Advert</h1></div>
                    <div><v-btn large class="grey darken-3 white--text ml-0" depressed @click="MIX_cancelDocument()"><v-icon>mdi-close</v-icon></v-btn></div>
                </div>
                <v-row class="pa-5">
                    <v-col cols="12">
                        <v-text-field background-color="white" v-model="form.advertName" filled label="Name" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select background-color="white" v-model="form.advertType" :items="advertTypes" filled label="Type" hide-details="auto" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-select>
                    </v-col>                    
                    <v-col cols="12">
                        <div class="pb-5 body-1 text-left grey--text text--darken-1">
                            Add Documents for example, quotes, drawings or more detailed specification to this Job which the
                            customer can view.
                        </div>
                        <input
                            type="file"
                            ref="fileinput"
                            style="display: none"
                            @change="previewFile"
                            capture="filesystem"
                            :accept="form.advertType === 'Images' ? '.jpg, .jpeg, .png' : '.mp4'"
                        />
                        <v-btn
                            :loading="loadingFileUpload"
                            :disabled="loadingFileUpload"
                            block
                            @click.native="fileClick"
                            class="primary"
                            dark
                        >
                            Upload File
                        </v-btn>
                        <v-row class="px-4 py-5 mb-0" v-if="isUploading">
                            <v-progress-linear color="primary" v-model="uploadValue" height="25">
                                <strong>{{ Math.ceil(uploadValue) }}%</strong>
                            </v-progress-linear>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-carousel v-if="form.advertType === 'Images'" hide-delimiters continuous cycle :interval="5000" :show-arrows="true" width="600px" style="max-width: 600px !important;">
                            <v-carousel-item
                            reverse-transition="fade-transition"
                              transition="fade-transition"
                              v-for="(file,i) in files"
                              :key="i"
                              :src="file.fileURL"
                            ></v-carousel-item>
                        </v-carousel>
                    </v-col>
                    <v-col cols="12" v-if="form.advertType === 'Video'">
                        <vue-core-video-player :src="files[0].fileURL" loop autoplay :controls="true"></vue-core-video-player>
                    </v-col>

                    <v-col cols="6" class="text-left">
                        <v-btn large class="grey lighten-1 white--text ml-0" depressed @click="MIX_cancelDocument()">Cancel</v-btn>
                    </v-col>
                    <v-col cols="6" class="text-right">
                        <v-btn v-if="MIX_formMode === 'Add'" large class="primary white--text ml-0" depressed @click="FB_createDocument(collectionName,form)">Save</v-btn>
                        <v-btn v-if="MIX_formMode === 'Edit'" large class="primary white--text ml-0" depressed @click="FB_updateDocument(collectionName,form)">Update</v-btn>
                    </v-col>
                </v-row>
            </v-col>            
        </v-row>
    </div>
</template>

<script>
    export default {
        data: () => ({
            deleteOverlay: false,
            deleteItem: {
                id: '',
                collectionName: '',
            },
            defaultForm: {
                id: '',
                advertName: '',
                advertType: '',
            },
            form: {
                id: '',
                advertName: '',
                advertType: '',
            },
            collectionName: 'adverts',
            headers: [
                // { text: 'ID', value: 'id' },
                { text: 'Advert Name', value: 'advertName' },
                { text: 'Advert Type', value: 'advertType' },
                { text: '', value: 'actions', align: 'right' }
            ],
            advertTypes: ['Images', 'Video'],
            documents: [],
            files:[],
            result: {},
            search: '',
            loadingFileUpload: false,
            uploadValue: 0,
            fileData: null

        }),
        watch: {
            // WATCH FOR CHANGES IN fileData
            fileData: async function () {
                var t = this
                var docLink = {
                    type: 'advert',
                    collection: 'adverts',
                    documentId: t.form.id,
                }
                var uploadFileResult = await t.FB_uploadFile(`/adverts/${t.form.id}`, t.fileData, docLink)
                if (uploadFileResult.code === 1) {
                    this.loadingFileUpload = false
                    t.getFiles()
                } else {
                    this.loadingFileUpload = false
                }
                console.log(JSON.stringify(uploadFileResult, null, 2))
            },
        },
        methods: {
            editDocument: async function (id) {
                this.MIX_panel('show');
                var document = this.documents.find(e => e.id === id);
                if (document !== undefined) {
                    this.form = JSON.parse(JSON.stringify(document));
                    this.getFiles();
                }
            },
            deleteDocument: async function(id) {
                var t = this;
                console.log('deleteDocument ' + id);
                var documentResult = await t.FB_deleteDocument(t.collectionName, id);
                t.result = documentResult;
                this.deleteOverlay = false
                this.documents = await this.getDocuments('documents');
            },
            getDocuments: async function() {
                let t = this;
                var collection = t.$firebase.db.collection(t.collectionName)
                collection.onSnapshot(function(querySnapshot) {
                    t.documents = [];
                    querySnapshot.forEach(function(doc) {
                        var document = doc.data()
                        document.id = doc.id;
                        /*eslint-disable */
                        if (!document.hasOwnProperty('delete')) {
                            t.documents.push (document);
                        }
                        /*eslint-enables */
                    });
                });
            },
            fileClick() {
                this.$refs.fileinput.click()
            },
            previewFile: async function (event) {
                this.loadingFileUpload = true
                this.uploadValue = 0
                //this.fileURL = null;
                this.fileData = event.target.files[0] // asign photoData for preview
            },
            // GET FILES SNAPSHOT FROM FIRESTORE
            getFiles() {
                let t = this
                console.log('get files for document ' + this.form.id)

                t.$firebase.db
                    .collection('files')
                    .where('docLink.documentId', '==', t.form.id)
                    .where('docLink.type', '==', 'advert')
                    .onSnapshot((querySnapshot) => {
                        console.log('clear files')
                        t.files = []
                        querySnapshot.forEach((doc) => {
                            var file = doc.data()
                            file.id = doc.id
                            t.files.push(file)
                            console.log('pushed file')
                        })
                    })
            },
        },
        async mounted() {
          await this.getDocuments();
          
        }
    }
</script>

