<template>
    <div fill-height class="d-flex align-stretch flex-grow-1">
        <v-row no-gutters fill-height class="pink">
            <v-col :cols="leftColumn"  v-if="leftColumn > 0" class="grey lighten-2 text-left" fill-height>
                <div class="d-flex justify-space-between grey darken-2 pa-5 align-center">
                    <div><h1 class="white--text">Preview</h1></div>
                    <v-spacer />
                </div>
                <div v-if="$vuetify.breakpoint.smAndDown" class="grey darken-2 pb-4 px-5"><v-text-field dense solo clearable background-color="white" v-model="search"  label="Search" hide-details="auto"></v-text-field></div>                
                <v-row class="pa-5">
                    <v-col cols="4" v-for="zone in documents" :key="zone.id" class="d-flex flex-column align-center justify-center">
                        <div class="text-h4 my-2 font-weight-bold">{{ zone.zoneName }} ({{ zone.screen }})</div>
                        <div id="wrap" style="" class="">
                            <iframe id="scaled-frame" :src="`/zone/${zone.id}`"></iframe>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
           
        </v-row>
    </div>
</template>

<script>
    export default {
        data: () => ({
            collectionName: 'zones',
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Destination Name', value: 'destinationName' },
                { text: '', value: 'actions', align: 'right' }
            ],
            documents: [],
            result: {},
            search: ''
        }),
        methods: {
            editDocument: async function (id) {
                this.MIX_panel('show');
                var document = this.documents.find(e => e.id === id);
                if (document !== undefined) {
                    this.form = JSON.parse(JSON.stringify(document));
                }
            },
            getDocuments: async function() {
                let t = this;
                var collection = t.$firebase.db.collection(t.collectionName)
                collection.onSnapshot(function(querySnapshot) {
                    t.documents = [];
                    querySnapshot.forEach(function(doc) {
                        var document = doc.data()
                        document.id = doc.id;
                        /*eslint-disable */
                        if (!document.hasOwnProperty('delete')) {
                            t.documents.push (document);
                        }
                        /*eslint-enables */
                    });
                });
            }
        },
        async created() {
          await this.getDocuments();
        }
    }
</script>

<style>
#wrap {
  width: 500px;
  height: 309px;
  padding: 0;
  overflow: hidden;
}

#scaled-frame {
  width: 1920px;
  height: 1080px;
  border: 0px;
}

#scaled-frame {
  zoom: 1;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.26);
  -webkit-transform-origin: 0 0;
}
</style>


