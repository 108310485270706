<template>
    <div class="d-flex flex-column justify-center">
        <v-overlay :value="loginOverlay" opacity="0.8">
            <v-col cols="12" class="d-flex flex-column align-center">
                <v-progress-circular
                    indeterminate
                    class="mb-4"
                    size="64"
                ></v-progress-circular>
                <div class="text-h4 font-weight-thin text-center">
                    Logging In...
                </div>
            </v-col>
        </v-overlay>
        <v-row>
            <v-col cols="12">
                <v-form
                    ref="loginForm"
                    v-on:submit.prevent="login()"
                    v-model="valid"
                    lazy-validation
                >
                    <v-text-field
                        solo
                        class="mb-3"
                        v-model.trim="userEmail"
                        background-color="white"
                        label="Email"
                        append-icon="mdi-email"
                        hide-details="auto"
                        :rules="emailRules"
                        required
                    ></v-text-field>
                    <v-text-field
                        solo
                        class="mb-12"
                        v-model.trim="userPassword"
                        background-color="white"
                        label="Password"
                        :append-icon="
                            showPassword ? 'mdi-lock-alert' : 'mdi-lock'
                        "
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        :rules="passwordRules"
                        hide-details="auto"
                        required
                        autocomplete
                        v-on:keyup.enter="login()"
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col
                cols="12"
                class="d-flex justify-space-around flex-column align-center"
            >
                <v-btn
                    block
                    large
                    class="primary mt-2 mb-1 white--text"
                    @click.native="login()"
                >
                    LOGIN
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "Login",
    data: () => ({
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        loading: false,
        loginOverlay: false,
        passwordRules: [(v) => !!v || "Password is required"],
        showPassword: false,
        userEmail: "",
        userPassword: "",
        valid: true,
    }),
    methods: {
        ...mapActions({
            ACT_currentUser: "ACT_currentUser",
        }),
        login() {
            let t = this;
            t.loginOverlay = true;
            t.userEmail = t.userEmail.toLowerCase();
            if (t.$refs.loginForm.validate()) {
                t.$firebase.auth
                    .signInWithEmailAndPassword(t.userEmail, t.userPassword)
                    .then(async (data) => {
                        t.loading = true;
                        t.checkValidationUser(data);
                        var user = await t.checkCurrentUser(data.user.uid);
                        if (!user) {
                            t.MIX_alert(
                                -1,
                                "Please verify your email address and/or a member of the TFW Team needs to approve the account.",
                                null,
                                null
                            );
                            t.loginOverlay = false;
                        }
                    })
                    .catch((err) => {
                        console.log("err: " + err);
                        t.MIX_alert(-1, err.message, null, null);
                        t.loginOverlay = false;
                    });
            } else {
                t.loginOverlay = false;
                t.MIX_alert(
                    -1,
                    "The email password combination is incorrect.",
                    null,
                    null
                );
            }
        },
        checkValidationUser(data) {
            let t = this;
            if (data.user.emailVerified === true) {
                t.$firebase.db
                    .collection("users")
                    .doc(data.user.uid)
                    .update({ userEmailVerified: true });
                t.ACT_currentUser(data.user);
            }
        },
        checkCurrentUser(userID) {
            let t = this;
            var currentUser = [];
            t.$firebase.db
                .collection("users")
                .doc(userID)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        var document = doc.data();
                        document.id = doc.id;
                        currentUser = document;
                        if (
                            document.userEmailVerified === true &&
                            document.userVerified === true
                        ) {
                            t.ACT_currentUser(currentUser);
                            t.$router
                                .push("/welcome")
                                .catch((error) => console.log(error));
                        } else {
                            t.MIX_alert(
                                -1,
                                "Please verify your email address and/or a member of the TFW Team needs to approve the account.",
                                null,
                                null
                            );
                            t.loginOverlay = false;
                        }
                    } else {
                        currentUser = null;
                        t.MIX_alert(-1, "Account not found.", null, null);
                    }
                });
            return currentUser;
        },
    },
};
</script>

<style scoped></style>
