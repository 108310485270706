<template>
    <v-container class="d-flex flex-column align-center">
        <v-col cols="12" class="flex-shrink-1">
            <img alt="TFW Logo" src="../../assets/logo.png">
        </v-col>
        <v-col cols="6" class="flex-grow-1">
            <FirebaseRegister />
        </v-col>
        <v-col cols="6" class="flex-shrink-1">
            <div class="white--text">Already have an account? <span class="primary--text" @click="MIX_go('/')">Login</span></div>
        </v-col>
    </v-container>
</template>

<script>

export default {
    name: "Register",
};
</script>
