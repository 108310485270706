<template>
        <div class="d-flex align-center justify-content black flex-column">
            <div style="font-size: 13em; color: #fc0000" class="font-weight-bold" :class="message.messageType+'--text'">
              {{ message.messageTitle }}
            </div>
            <div style="font-size: 8em; color: #fc0000" :class="'white--text'">
              {{ message.messageSubtitle }}
            </div>
        </div>
</template>

<script>

/* TODO: - README:
* - Added ability to choose zone message from /admin/zones. Admin can toggle each zone, and can switch messages on the fly.
* - Created <messages-screen/> in order to show messages, this uses firebase data and router paths to determine the correct message to show.
* - Added top bar to all /admin pages.
* - Integrated all dynamic data with firebase.
*
* FIXME - Bugs:
* - Messages do not seem to update without a page refresh.
* */

export default {
  data: () => ({
    messageArray: [],
    zoneArray: [],
    zone: {},
    message: {},
    messageCollectionName: "messages",
    zoneCollectionName: "zones",
    carouselModel: 0,
    messagesLength: 0,
    messageTime: 0,
    pageStatus: "loading"
  }),
  methods: {
    //Grabs zone from Firebase
    getZone: async function () {
      return new Promise((resolve) => {
        let t = this;
        t.$firebase.db.collection("zones").doc(t.$route.params.id.toUpperCase()).onSnapshot((doc) => {
          t.zone = doc.data()
          resolve(doc.data());
        });
      })
    },
    //Grabs message for specific zone from Firebase
    getZoneMessage: async function () {
      /* TODO FIXME
      Zone message is returned as undefined. getZoneMessage seems to be running too early
       */
      return new Promise((resolve) => {
        let t = this;
        t.$firebase.db.collection("messages").doc(t.zone.messageId).onSnapshot((doc) => {
          t.message = doc.data();
          resolve(doc.data());
        })
      })
    },
  },
  async created() {
    //Using created as runs before DOM
    const t = this;
    await t.getZone()
    await t.getZoneMessage();
    t.pageStatus = "done";
  },
}
</script>
<style>
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  /* Whatever width you want */
}
</style>