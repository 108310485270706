import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCtb7egbvKBiEwv31ctRt4TOqWNriPokn4",
    authDomain: "vindishow-tfw.firebaseapp.com",
    projectId: "vindishow-tfw",
    storageBucket: "vindishow-tfw.appspot.com",
    messagingSenderId: "83941747087",
    appId: "1:83941747087:web:3d4d8f9dc5e1fffd79c3fa",
    measurementId: "G-EP6B65N0KN"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;
const fv = firebase.firestore.FieldValue;

export {
    db,
    storage,
    auth,
    currentUser,
    fv
};