<template>
    <div id="app">
        <v-app>
            <section v-if="$route.path.includes('admin')">
                <Navigation />
            </section>
            <v-main class="pa-0">
                <alertbox />
                <v-overlay :value="languageOverlay" opacity="0.85">
                    <v-col cols="12" class="d-flex flex-column align-center">
                        <v-card class="pa-6">
                            <div class="body-1 pb-8">
                                Please select your preferred language
                            </div>
                            <v-row>
                                <v-col cols="12">
                                    <div>
                                        <Translator :countries="countries" @on-country-click="languageOverlay = false"/>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn block class="darkgrey" @click="languageOverlay = false"
                                        >Cancel</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-overlay>  
                <router-view />
            </v-main>
            <!-- <v-footer v-if="$route.path.includes('admin')" class="appdarkbackground d-flex justify-start">
                <v-btn class="primary" @click="languageOverlay = true">Select Language</v-btn>
            </v-footer> -->
        </v-app>
    </div>
</template>

<script>
import { Translator } from "vue-google-translate";
export default {
    data: () => ({
        languageOverlay: false,
        countries: [
        {
          code: "en|en",
          title: "English",
        },
        {
          code: "en|cy",
          title: "Welsh",
        },
        {
          code: "en|fr",
          title: "French",
        },
      ],
    }),
    components: {
        Translator,
    },
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #212121 !important;
}

tbody {
    tr:hover {
        background-color: transparent !important;
    }
}

.headermargin {
    margin-top: 50px !important;
}

.language__text {
    color: white !important;
    margin-left: 12px !important;
}

/* gets rid of the translate banner at the top of the page */
body {
  top: 0 !important;
}
/* get rids of the translate banner at the bottom of the web page */
.skiptranslate {
  display: none !important;
}
</style>
