<template>
    <div fill-height class="d-flex align-stretch flex-grow-1 headermargin">
        <DeleteOverlay :deleteOverlay="deleteOverlay" :deleteItem="deleteItem" @changeDeleteOverlay="deleteOverlay = false"/>
        <v-row no-gutters fill-height class="pink">
            <v-col :cols="leftColumn"  v-if="leftColumn > 0" class="grey lighten-2 text-left" fill-height>
                <div class="d-flex justify-space-between grey darken-2 pa-5 align-center">
                    <div><h1 class="white--text">Destinations</h1></div>
                    <v-spacer />
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="mr-4"><v-text-field dense solo clearable background-color="white" v-model="search"  label="Search" hide-details="auto"></v-text-field></div>
                    <div><v-btn large class="primary white--text ml-0" depressed @click="MIX_formMode = 'Add', form = defaultForm; MIX_panel('show');">Add <span v-if="$vuetify.breakpoint.mdAndUp">Destination</span></v-btn></div>
                </div>
                <div v-if="$vuetify.breakpoint.smAndDown" class="grey darken-2 pb-4 px-5"><v-text-field dense solo clearable background-color="white" v-model="search"  label="Search" hide-details="auto"></v-text-field></div>                
                <v-data-table :search="search" :headers="headers" :items="documents" :items-per-page="20" class="elevation-1" >
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn class="darken-3 ml-0" depressed @click="MIX_formMode='Edit'; editDocument(item.id)"><v-icon class="black--text">mdi-pencil</v-icon></v-btn>
                        <v-btn class="darken-3 ml-1" depressed @click="deleteOverlay = true, deleteItem.id = item.id, deleteItem.collectionName = collectionName"><v-icon class="black--text">mdi-delete</v-icon></v-btn>
                    </template>                
                </v-data-table>
            </v-col>
            <v-col :cols="rightColumn" v-if="rightColumn > 0" class="grey lighten-3">
                <div class="d-flex align-center justify-space-between grey darken-3 pa-5">
                    <div><h1 class="white--text">{{ MIX_formMode }} Destination</h1></div>
                    <div><v-btn large class="grey darken-3 white--text ml-0" depressed @click="MIX_cancelDocument()"><v-icon>mdi-close</v-icon></v-btn></div>
                </div>
                <v-row class="pa-5">
                    <v-col cols="12">
                        <v-text-field background-color="white" v-model="form.destinationName" filled label="Name" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="text-left">
                        <v-btn large class="grey lighten-1 white--text ml-0" depressed @click="MIX_cancelDocument()">Cancel</v-btn>
                    </v-col>
                    <v-col cols="6" class="text-right">
                        <v-btn v-if="MIX_formMode === 'Add'" large class="primary white--text ml-0" depressed @click="saveDestination()">Save</v-btn>
                        <v-btn v-if="MIX_formMode === 'Edit'" large class="primary white--text ml-0" depressed @click="updateDestination()">Update</v-btn>
                    </v-col>
                </v-row>
            </v-col>            
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "Destinations",
        data: () => ({
            deleteOverlay: false,
            deleteItem: {
                id: '',
                collectionName: '',
            },
            defaultForm: {
                destinationName: '',
            },
            form: {
                destinationId: '',
                destinationName: '',
                id: '',
            },
            collectionName: 'destinations',
            headers: [
                // { text: 'ID', value: 'id' },
                { text: 'ID', value: 'destinationId' },
                { text: 'Destination Name', value: 'destinationName' },
                { text: '', value: 'actions', align: 'right' }
            ],
            documents: [],
            result: {},
            search: ''
        }),
        methods: {
            editDocument: async function (id) {
                this.MIX_panel('show');
                var document = this.documents.find(e => e.id === id);
                if (document !== undefined) {
                    this.form = JSON.parse(JSON.stringify(document));
                }
            },
            updateDestination() {
                var t = this;
                t.form.destinationId = t.form.destinationName
                this.FB_updateDocument(t.collectionName,t.form)
            },
            saveDestination() {
                var t = this;
                t.form.id = t.form.destinationName
                t.form.destinationId = t.form.destinationName
                this.FB_createDocument(t.collectionName,t.form)
            },
            getDocuments: async function() {
                let t = this;
                var collection = t.$firebase.db.collection(t.collectionName)
                collection.onSnapshot(function(querySnapshot) {
                    t.documents = [];
                    querySnapshot.forEach(function(doc) {
                        var document = doc.data()
                        document.id = doc.id;
                        /*eslint-disable */
                        if (!document.hasOwnProperty('delete')) {
                            t.documents.push (document);
                        }
                        /*eslint-enables */
                    });
                });
            }
        },
        async mounted() {
          await this.getDocuments();
        }
    }
</script>

