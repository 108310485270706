import Vue from 'vue';

// * FIREBASE INTEGRATION
const firebase = require("@/modules/firebase/firebase-config.js"); // Import Firebase Config for Firebase Object Access
Vue.prototype.$firebase = firebase; // Prototype Firebase for use throughout Project $firebase
import firebase_mixin from '@/modules/firebase/firebase_mixin.js'; // Import Firebase Mixin for Global CRUD Functions
Vue.use(firebase_mixin); // User Firebase Mixin within Vue
import firebase_component from '@/modules/firebase/firebase_component.vue';
Vue.component('firebase', firebase_component);

//* FIREBASE AUTH COMPONENTS
import firebaseLogin from '@/components/firebase/FirebaseLogin.vue'
Vue.component('FirebaseLogin', firebaseLogin)

import firebaseRegister from '@/components/firebase/FirebaseRegister.vue'
Vue.component('FirebaseRegister', firebaseRegister)
