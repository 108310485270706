import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const opts = {
    options: {
        customProperties: true
    },
    theme: {
        themes: {
            light: {
                //info: '#E0E0E0',
                accent: '#1993E0',
                appdarkbackground: '#212121',
                appbackground: '#E5E5E5', //'#F2F2F2',
                darkgrey: '#4f4f4f', //'#4f4f4f',
                error: '#E10203',
                info: '#ffffff',
                lightgrey: '#E0E0E0',
                notificationsBackground: '#EEEEEE',
                primary: '#BC0703',
                secondary: '#FFFFFF',
                success: '#00942A',
                vindicoPurple: '#582468',
                warning: '#E04B0D',
            },
        },
    },
    breakpoint: {
        thresholds: {
            xs: 340,
            sm: 600, // 540,
            md: 800,
            lg: 1501,  // sets Ipad / surface book
        },
        scrollBarWidth: 24,
    },
};

export default new Vuetify(opts);
