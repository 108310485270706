<template>
    <v-snackbar
        dark
        bottom
        v-model="alert.show"
        class="rounded-sm"
        :color="alert.color"
        :timeout="alert.timeout"
        :positionTop="alert.positionTop"
        :positionBot="alert.positionBot"
    >
        <span class="white--text">{{ alert.message }}</span>

        <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="hideAlert">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
    data: () => ({
        alert: {
            code: 1,
            color: "success",
            timeout: 2000,
            message: "Success",
            data: null,
            error: null,
            // positionTop: false,
            // positionBot: false,
            show: false,
        },
    }),
    computed: {
        ...mapState("alertbox_store/", {
            alertbox: (state) => state.alert,
        }),
    },
    watch: {
        alertbox: {
            handler: function () {
                this.alert = this.alertbox;
            },
            deep: true,
        },
    },
    methods: {
        hideAlert() {
            this.alert.show = false;
        },
    },
};
</script>
