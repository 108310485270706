<template>
    <v-container>
        <v-row>
            <v-app-bar
                app
                v-if="$route.path.includes('welcome')"
                class="black"
                absolute
            >
                <img src="@/assets/tfwlogo.png" height="40" />
            </v-app-bar>
        </v-row>
        <v-col cols="12" class="d-flex justify-start headermargin">
            <div class="text-h3 font-weight-thin white--text">Welcome, {{ GET_currentUser.userFirstName }}</div>
        </v-col>
        <v-col cols="12" class="d-flex" :class="$vuetify.breakpoint.mdAndDown ? 'flex-column' : 'flex-row'">
            <v-col cols="12" lg="3" class="d-flex" :class="$vuetify.breakpoint.lgAndDown ? 'justify-center' : ''">
                <v-card class="primary pa-4 rounded-lg" width="200" @click="MIX_go('/admin/zones')">
                    <v-card-title class="white--text d-flex justify-center">All Zones</v-card-title>
                    <v-icon size="56" color="white">mdi-television</v-icon>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" class="d-flex" :class="$vuetify.breakpoint.lgAndDown ? 'justify-center' : ''">
                <v-card class="primary pa-4 rounded-lg" width="200" @click="MIX_go('/admin/trains')">
                    <v-card-title class="white--text d-flex justify-center">Trains</v-card-title>
                    <v-icon size="56" color="white">mdi-train</v-icon>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" class="d-flex" :class="$vuetify.breakpoint.lgAndDown ? 'justify-center' : ''">
                <v-card class="primary pa-4 rounded-lg" width="200" @click="MIX_go('/admin/messages')">
                    <v-card-title class="white--text d-flex justify-center">Messages</v-card-title>
                    <v-icon size="56" color="white">mdi-forum</v-icon>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" class="d-flex" :class="$vuetify.breakpoint.lgAndDown ? 'justify-center' : ''">
                <v-card class="primary pa-4 rounded-lg" width="200" @click="MIX_go('/admin/adverts')">
                    <v-card-title class="white--text d-flex justify-center">Adverts</v-card-title>
                    <v-icon size="56" color="white">mdi-image</v-icon>
                </v-card>
            </v-col>
        </v-col>
        <v-col cols="12" class="d-flex" :class="$vuetify.breakpoint.mdAndDown ? 'flex-column' : 'flex-row'">
            <v-col cols="12" lg="3" class="d-flex" :class="$vuetify.breakpoint.lgAndDown ? 'justify-center' : ''">
                <v-card class="primary pa-4 rounded-lg" width="200" @click="MIX_go('/admin/destinations')">
                    <v-card-title class="white--text d-flex justify-center">Destinations</v-card-title>
                    <v-icon size="56" color="white">mdi-map-marker</v-icon>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" class="d-flex" :class="$vuetify.breakpoint.lgAndDown ? 'justify-center' : ''">
                <v-card class="primary pa-4 rounded-lg" width="200" @click="MIX_go('/admin/callingpatterns')">
                    <v-card-title class="white--text d-flex justify-center">Patterns</v-card-title>
                    <v-icon size="56" color="white">mdi-keyboard-tab</v-icon>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" class="d-flex" :class="$vuetify.breakpoint.lgAndDown ? 'justify-center' : ''">
                <v-card class="primary pa-4 rounded-lg" width="200" @click="MIX_go('/admin/users')">
                    <v-card-title class="white--text d-flex justify-center">Users</v-card-title>
                    <v-icon size="56" color="white">mdi-account</v-icon>
                </v-card>
            </v-col>
            <v-col cols="12" lg="3" class="d-flex" :class="$vuetify.breakpoint.lgAndDown ? 'justify-center' : ''">
                <v-card class="primary pa-4 rounded-lg" width="200" @click="MIX_go('/admin/preview')">
                    <v-card-title class="white--text d-flex justify-center">Preview</v-card-title>
                    <v-icon size="56" color="white">mdi-television-guide</v-icon>
                </v-card>
            </v-col>
            <!-- <v-col cols="3" class="d-flex">
                <v-card class="primary pa-4 rounded-sm" width="200" @click="MIX_go('/admin/preview')">
                    <v-card-title class="white--text">Preview</v-card-title>
                    <v-icon size="56" color="white">mdi-television-guide</v-icon>
                </v-card>
            </v-col> -->
        </v-col>
        <v-col cols="12" lg="12">
            <div class="grey--text justify-end">Version {{ LATEST_VERSION}}</div>
        </v-col>
    </v-container>
</template>

<script> 
import { version } from '../../package.json'
export default {
    name: "Welcome",
    data: () => ({
        LATEST_VERSION: version,
    }),
};
</script>

<style scoped>
.headermargin {
    margin-top: 100px !important;
}
</style>