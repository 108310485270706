import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index.js";

import AdminCallingPattern from '@/views/admin/callingpatterns.vue'
import AdminDestinations from '@/views/admin/destinations.vue'
import AdminMessages from '@/views/admin/messages.vue'
import AdminTrains from '@/views/admin/trains.vue'
import AdminUsers from '@/views/admin/users.vue'
import AdminZones from '@/views/admin/zones.vue'
import Adverts from '@/views/admin/adverts.vue'
import Firebase from '@/modules/firebase/firebase_component.vue'
import Home from '@/views/Home.vue'
import MessagesScreen from '@/views/message'
import Preview from '@/views/admin/preview.vue'
import Register from '../views/auth/register.vue'
import Welcome from '@/views/welcome.vue'
import Zone from '@/views/zone.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/register-access',
        name: 'Register',
        component: Register
    },
    {
        path: '/welcome',
        name: 'Welcome',
        component: Welcome
    },
    {
        path: '/zone/:id?',
        name: 'Zone',
        component: Zone,
    },
    {
        path: '/admin/zones',
        name: 'AdminZones',
        component: AdminZones,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/messages',
        name: 'AdminMessages',
        component: AdminMessages,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/adverts',
        name: 'Adverts',
        component: Adverts,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/trains',
        name: 'AdminTrains',
        component: AdminTrains,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/destinations',
        name: 'AdminDestinations',
        component: AdminDestinations,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/callingpatterns',
        name: 'AdminCallingPattern',
        component: AdminCallingPattern,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/preview',
        name: 'Preview',
        component: Preview
    },
    {
        path: '/firebase',
        name: 'Firebase',
        component: Firebase,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/messages',
        name: 'Messages',
        component: MessagesScreen,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/',
        name: 'Admin',
        component: AdminZones,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/users',
        name: 'Users',
        component: AdminUsers,
        meta: { requiresAuth: true }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    if (requiresAuth) {
        let checkUser = store.getters.GET_currentUser;
        navigator.serviceWorker.register('/service-worker.js').then(reg => {
            // sometime later…
            reg.update();
        });
        if (!checkUser) {
            next("/");
        } else {
            next();
        }
    } else next();
});

export default router
