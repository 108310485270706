import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import moment from 'moment';
import _ from 'lodash-es';
import JsonCSV from 'vue-json-csv'

import '../node_modules/animate.css/animate.min.css'; // import animate css

Vue.prototype.$moment = moment;
Vue.prototype.$_ = _;

import Navigation from './components/Navigation.vue'
Vue.component('Navigation', Navigation)

import DeleteOverlay from './components/DeleteOverlay.vue'
Vue.component('DeleteOverlay', DeleteOverlay)

import MarqueeText from 'vue-marquee-text-component'
Vue.component('marquee-text', MarqueeText)

import MessagesScreen from '../src/views/message'
Vue.component("messages-screen", MessagesScreen)

import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer)

import VTextMarquee from 'vue-text-marquee';
Vue.use(VTextMarquee);

import mixins from './modules/mixins.js';
Vue.use(mixins);

require('@/modules/firebase/firebase_import.js')
require('@/modules/alertbox/alertbox_import.js');

Vue.component('downloadCsv', JsonCSV)

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
